import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ArrowDownCircle, ArrowUpCircle } from 'lucide-react';

const colors = [
  'bg-green-50',
  'bg-blue-50',
  'bg-yellow-50',
  'bg-purple-50',
  'bg-pink-50',
  'bg-gray-50',
];

// Словарь для замен
const reasonMap = {
  deactiv: 'Снято с публикации',
  activ: 'Активировано',
  price: 'Изменение цены',
  description: 'Изменено описание',
  name: 'Изменено название',
  link: 'Изменена ссылка',
  promotion: 'Промо',
  promotion_stop: 'Остановка промо',
  create: 'Создано',
  room: 'Изменена планировка',
  square: 'Изменена площадь',
};

const HistorySection = ({ subjectId }) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const sectionRef = useRef(null);

  const fetchHistory = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/dashboard/api/subject/${subjectId}/history/`,
        { withCredentials: true }
      );

      const filteredHistory = response.data.filter((record) => {
        return (
          (record.history_change_reason &&
            !record.history_change_reason.includes('price_meter')) ||
          record.history_type === '+'
        );
      });

      const finalHistory = filteredHistory.map((record) => {
        if (!record.history_change_reason && record.history_type === '+') {
          return { ...record, history_change_reason: 'create' };
        }
        return record;
      });

      setHistory(finalHistory);
    } catch (error) {
      console.error('Error fetching history:', error);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasLoaded) {
            fetchHistory();
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [subjectId, hasLoaded]);

  const renderReason = (reasonRaw) => {
    if (!reasonRaw) return null;

    const parts = reasonRaw.split(/[\s|]+/); // разделим по "price | raising" или "price and drop"
    const mainReason = parts.find((part) => reasonMap[part]);
    const direction = parts.includes('raising')
      ? 'up'
      : parts.includes('drop')
      ? 'down'
      : null;

    const text = reasonMap[mainReason] || reasonRaw;

    return (
      <span className="inline-flex items-center gap-1">
        {text}
        {direction === 'up' && <ArrowUpCircle className="w-4 h-4 text-emerald-500" />}
        {direction === 'down' && <ArrowDownCircle className="w-4 h-4 text-rose-500" />}
      </span>
    );
  };

  return (
    <div
      ref={sectionRef}
      className="mt-4 border-t pt-3 border-gray-200 space-y-2"
    >
      <h4 className="text-sm font-semibold text-gray-700 mb-2">История объекта</h4>
      {isLoading ? (
        <p className="text-xs text-gray-500">Загрузка...</p>
      ) : hasLoaded && history.length === 0 ? (
        <p className="text-xs text-gray-500">История отсутствует</p>
      ) : (
        hasLoaded && (
          <div className="space-y-2">
            {history.map((record, index) => (
              <div
                key={`${record.id}-${index}`}
                className={`p-3 rounded-md ${colors[index % colors.length]} text-xs sm:text-sm shadow-sm border border-gray-200`}
              >
                <p className="text-gray-700 mb-1">
                  <span className="font-semibold">Дата:</span>{' '}
                  {new Date(record.history_date).toLocaleString()}
                </p>
                {'price' in record && (
                  <p className="text-gray-700 mb-1">
                    <span className="font-semibold">Цена:</span>{' '}
                    {record.price?.toLocaleString() ?? '—'} ₽
                  </p>
                )}
                <p className="text-gray-700">
                  <span className="font-semibold">Причина:</span>{' '}
                  {renderReason(record.history_change_reason)}
                </p>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default HistorySection;
