// src/components/StartProcess.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Select, message, Form, Checkbox } from "antd";

const { Option } = Select;

const StartProcess = ({ user }) => {
  const [configFiles, setConfigFiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedConfigFile, setSelectedConfigFile] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [headless, setHeadless] = useState(true);

  // Аналог SubjectListLite:
  // При каждом запросе используем `withCredentials: true`, чтобы передавать sessionid

  useEffect(() => {
    // Загрузка списка конфигов
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/config-files/`, {
        withCredentials: true, // <-- важно
      })
      .then((response) => {
        setConfigFiles(response.data);
        // console.log("Загруженные конфигурации:", response.data);

      })
      .catch((error) => {
        console.error("Ошибка загрузки конфигурационных файлов:", error);
        message.error("Не удалось загрузить конфигурационные файлы.");
      });
  }, []);

  const fetchProfiles = () => {
    setLoadingProfiles(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/profiles/`, {
        withCredentials: true, // <-- важно
      })
      .then((response) => {
        setProfiles(response.data);
      })
      .catch((error) => {
        console.error("Ошибка загрузки профилей:", error);
        message.error("Не удалось загрузить профили.");
      })
      .finally(() => {
        setLoadingProfiles(false);
      });
  };

  const handleStartProcess = () => {
    // Проверяем, залогинен ли пользователь
    if (!user) {
      message.warning("Сначала войдите в систему (пользователь не аутентифицирован).");
      return;
    }

    if (!selectedConfigFile) {
      message.warning("Выберите конфигурационный файл для запуска процесса.");
      return;
    }

    if (!selectedProfile) {
      message.warning("Выберите профиль GoLogin для запуска процесса.");
      return;
    }

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/start-process/`,
        {
          config_file: selectedConfigFile,
          profile_id: selectedProfile,
          headless: headless,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          withCredentials: true, // <-- ключевая строчка, шлём куки (sessionid)
        }
      )
      .then((response) => {
        message.success(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          console.error("Ошибка при запуске процесса:", status, data);
          if (data && data.error) {
            message.error(`Ошибка: ${data.error}`);
          } else {
            message.error("Ошибка при запуске процесса. Код " + status);
          }
        } else {
          message.error("Ошибка при запуске процесса (нет ответа).");
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderProfileOption = (profile) => {
    const statusLabel = profile.status === "logged_in" ? "Залогинен" : "Незалогинен";
    const usageLabel = profile.usage === "free" ? "Свободен" : "Занят";
    const description = profile.description || "";
    return (
      <Option key={profile.id} value={profile.id}>
        {`${description} (${statusLabel}, ${usageLabel})`}
      </Option>
    );
  };

  return (
    <div style={{ marginBottom: "20px", paddingLeft: "20px", paddingRight: "20px" }}>
      <Form layout="inline">
        <Form.Item>
        <Select
          placeholder="Выберите конфигурационный файл"
          value={selectedConfigFile}
          onChange={setSelectedConfigFile}
          style={{ width: "300px" }}
        >
          {configFiles.map((file) => {
            const isTest = file.general_mapping === false;
            const label = isTest ? `test — ${file.city}` : file.city;
            return (
              <Option key={file.filename} value={file.filename}>
                {label}
              </Option>
            );
          })}
        </Select>

        </Form.Item>
        <Form.Item>
          <Select
            placeholder="Выберите профиль GoLogin"
            value={selectedProfile}
            onChange={setSelectedProfile}
            style={{ width: "300px" }}
            loading={loadingProfiles}
            onDropdownVisibleChange={(open) => {
              if (open) {
                fetchProfiles();
              }
            }}
          >
            {profiles.map((profile) => renderProfileOption(profile))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={headless}
            onChange={(e) => setHeadless(e.target.checked)}
          >
            Headless
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleStartProcess} loading={isLoading}>
            Запустить процесс
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StartProcess;
