import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Menu } from 'lucide-react';
import CabinetPurchases from './CabinetPurchases';

const API_BASE = process.env.REACT_APP_API_URL;

function CabinetMain() {
  const [profile, setProfile] = useState(null);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axios.get(`${API_BASE}/api/accounts/cabinet/profile/`, {
          withCredentials: true,
        });
        setProfile(res.data);

        if (Array.isArray(res.data.allowed_cities) && res.data.allowed_cities.length > 0) {
          const cityRequests = res.data.allowed_cities.map((id) =>
            axios.get(`${API_BASE}/dashboard/api/cities/${id}/`, {
              withCredentials: true,
            })
          );
          const results = await Promise.all(cityRequests);
          const citiesData = results.map((r) => r.data);
          setCities(citiesData);
        }
      } catch (error) {
        console.error('Ошибка при загрузке профиля:', error);
      }
    };

    fetchProfile();
  }, []);

  if (!profile) {
    return <div className="p-4 text-gray-500">Загрузка профиля...</div>;
  }

  const fullName = `${profile?.user?.first_name || ''} ${profile?.user?.last_name || ''}`.trim();
  const balance = profile?.balance ?? 0;

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Профиль</h2>

      <div className="space-y-3 text-gray-700">
        <p><span className="font-semibold">ФИО:</span> {fullName || profile.username || '(не указано)'}</p>
        <p><span className="font-semibold">Email:</span> {profile.email || '—'}</p>
        <p><span className="font-semibold">Телефон:</span> {profile.phone || '—'}</p>
        <p><span className="font-semibold">Баланс:</span> <span className="text-emerald-600 font-bold">{balance} ₽</span></p>

        <div>
          <p className="font-semibold mb-1">Выбранные города:</p>
          {cities.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {cities.map((city) => (
                <span
                  key={city.id}
                  className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full"
                >
                  {city.name}
                </span>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">Нет выбранных городов</p>
          )}
        </div>
      </div>

      <button
        className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-5 py-2 rounded shadow"
        onClick={() => alert('Пополнение баланса (заглушка)')}
      >
        Пополнить баланс
      </button>
    </div>
  );
}

function CabinetPayments() {
  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <h2 className="text-xl font-bold mb-4">История платежей</h2>
      <p className="text-gray-500">Здесь будет список платежей...</p>
    </div>
  );
}

export default function CabinetPage() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    { label: 'Главная', to: '/cabinet' },
    { label: 'История платежей', to: '/cabinet/payments' },
    { label: 'История покупок', to: '/cabinet/purchases' },
  ];

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      {/* Мобильная кнопка меню */}
      <div className="flex md:hidden justify-between items-center bg-white px-4 py-3 shadow">
        <h1 className="text-xl font-bold text-gray-800">Кабинет</h1>
        <button onClick={() => setMenuOpen(!menuOpen)} aria-label="Меню">
          <Menu className="w-6 h-6 text-gray-700" />
        </button>
      </div>

      {/* Боковое меню */}
      <div
        className={`${
          menuOpen ? 'block' : 'hidden'
        } md:block w-full md:w-64 bg-white shadow-md p-6`}
      >
        <h1 className="text-2xl font-bold mb-6 text-gray-800 hidden md:block">Личный кабинет</h1>
        <nav className="space-y-2">
          {menuItems.map((item) => {
            const active = location.pathname === item.to;
            return (
              <Link
                key={item.to}
                to={item.to}
                className={`block px-4 py-2 rounded transition font-medium ${
                  active ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100 text-gray-700'
                }`}
                onClick={() => setMenuOpen(false)} // Закрывать меню на мобилках
              >
                {item.label}
              </Link>
            );
          })}
        </nav>
      </div>

      {/* Контент */}
      <div className="flex-1 p-4 sm:p-6">
        <Routes>
          <Route path="/" element={<CabinetMain />} />
          <Route path="payments" element={<CabinetPayments />} />
          <Route path="purchases" element={<CabinetPurchases />} />
        </Routes>
      </div>
    </div>
  );
}
