import React from 'react';

export default function ProxiedImage({ src, size }) {
  const proxyUrl = `${process.env.REACT_APP_API_URL}/dashboard/proxy?url=${encodeURIComponent(src)}`;

  return (
    <img
      src={proxyUrl}
      alt="Фото"
      className="rounded-md object-contain"
      style={{ width: size, height: size }}
    />
  );
}

