// src/components/AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getCsrfToken = () => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    return cookieValue;
  };

  const fetchWhoAmI = async () => {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/accounts/whoami/`,
      { withCredentials: true }
    );
    setUser(resp.data);
    return resp.data;
  };

  const handleLogin = async (username, password) => {
    setLoading(true);
    setErrorMessage('');
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/accounts/login/`,
        { username, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCsrfToken(),
          },
        }
      );
      await fetchWhoAmI(); // после успешного логина обновляем user
    } catch (err) {
      setErrorMessage('Неверные данные или ошибка логина');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/accounts/logout/`,
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCsrfToken(),
          },
        }
      );
      setUser(null); // сбрасываем user
    } catch (err) {
      setErrorMessage('Ошибка при выходе из аккаунта');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const autoCheckUser = async () => {
      try {
        setLoading(true);
        await fetchWhoAmI();
      } catch (err) {
        console.log('Пользователь не залогинен или ошибка whoami:', err);
      } finally {
        setLoading(false);
      }
    };
    autoCheckUser();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading, errorMessage, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);