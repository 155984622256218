import React from 'react';

export default function NoImagePlaceholder({ size }) {
  return (
    <div
      className="flex flex-col items-center justify-center text-gray-400 overflow-visible"
      style={{ width: size, height: size }}
    >
      <svg
        width={size * 0.4} // немного меньше
        height={size * 0.4}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mb-1"
      >
        <rect
          x="4"
          y="4"
          width="56"
          height="56"
          rx="4"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
        <circle
          cx="20"
          cy="20"
          r="5"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
        <path
          d="M60 60L40 36L24 48L10 34"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="text-xs text-gray-400">Нет изображения</span>
    </div>
  );
}
