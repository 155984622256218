import React from 'react';
import PurchaseButton from '../MAPS/PurchaseButton';
import SubjectImage from '../IMG/SubjectImage';

import {
  CheckCircle,
  XCircle,
  MapPin,
  ImageIcon,
  Repeat,
  History,
  TrendingUp,
} from 'lucide-react';

function FeatureBlock({ label, value, icon: Icon }) {
  const isTruthy =
    typeof value === 'boolean'
      ? value
      : typeof value === 'number'
      ? value > 0
      : typeof value === 'string'
      ? value.trim() !== ''
      : false;

  const colorClass = isTruthy ? 'text-emerald-500' : 'text-gray-400';
  const valueDisplay =
    typeof value === 'number' || typeof value === 'string'
      ? `: ${value}`
      : '';

  return (
    <div className="flex items-center bg-gray-50 border border-gray-200 rounded-md px-3 py-2 text-sm shadow-sm w-full">
      <Icon className={`w-4 h-4 mr-2 ${colorClass}`} />
      <span className="text-gray-700">
        {label}
        {valueDisplay}
      </span>
    </div>
  );
}

export default function DefaultCard({ subject, onSubjectUpdate, setSubjects,  onGlobalSubjectUpdate }) {
  const {
    id,
    name,
    adress,
    activ,
    has_photos,
    history_counts,
    duplicates_count,
  } = subject;

  const statusEmoji = activ ? (
    <span className="text-green-600 flex items-center text-sm">
      <CheckCircle className="w-4 h-4 mr-1" />
      Активно
    </span>
  ) : (
    <span className="text-red-500 flex items-center text-sm">
      <XCircle className="w-4 h-4 mr-1" />
      Неактивно
    </span>
  );

  const priceChangeCount = history_counts?.price_change_count ?? 0;
  const targetChangeCount = history_counts?.target_change_count ?? 0;

  return (
    <div className="p-4 bg-white rounded-xl shadow-md font-sans w-full min-h-[345px] flex flex-col md:flex-row gap-4">
      {/* Фото */}
      <div className="w-full md:w-[160px] flex md:block items-center justify-start">
        <div className="flex flex-col items-center justify-start md:justify-start w-full">
          <SubjectImage subjectId={id} size={144} />
          <div className="text-xs text-gray-500 mt-2">{statusEmoji}</div>
        </div>
      </div>

      {/* Контент */}
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <h2 className="font-semibold text-lg text-gray-900 mb-1">
            {name || `Объект #${id}`}
          </h2>
          {adress && (
            <div className="text-sm text-gray-500 flex items-center mb-4">
              <MapPin className="w-4 h-4 mr-1" />
              {adress}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <FeatureBlock
            label="Фото"
            value={has_photos}
            icon={ImageIcon}
          />
            <FeatureBlock
              label="Изм. цен"
              value={priceChangeCount}
              icon={TrendingUp}
            />
            <FeatureBlock
              label="Историч. записи"
              value={targetChangeCount}
              icon={History}
            />
            <FeatureBlock
              label="Дубликаты"
              value={duplicates_count}
              icon={Repeat}
            />
              <div className="flex items-center bg-blue-50 text-blue-600 border border-blue-200 rounded-md px-3 py-2 text-sm shadow-sm w-full">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span className="font-medium">Есть ссылка</span>
              </div>

          </div>
          
        </div>
        

        <div className="mt-4">
          <PurchaseButton
            subjectId={id}
            onPurchaseSuccess={onSubjectUpdate}
            setSubjects={setSubjects}
            onGlobalSubjectUpdate={onGlobalSubjectUpdate}
            className="w-full md:w-auto"
          />
        </div>
      </div>
    </div>
  );
}
