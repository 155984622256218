import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PurchasedCard from '../SUBLIST/PurchasedCardList';
import { useAuth } from '../AUTH/AuthProvider';

export default function CabinetPurchases() {
  const { user } = useAuth();
  const [purchasedSubjects, setPurchasedSubjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPurchases = async () => {
      if (!user?.id) return;

      try {
        // 1. Получаем список купленных subject.id
        const res = await axios.get(
          `${baseURL}/api/user/${user.id}/purchased-subjects/`,
          { withCredentials: true }
        );

        const subjectIds = res.data.results?.map((item) => item.id) || [];

        // 2. Загружаем данные о каждом объекте (extended_about)
        const extendedDataPromises = subjectIds.map((id) =>
          axios
            .get(`${baseURL}/dashboard/api/public/subject/${id}/extended_about/`, {
              withCredentials: true,
            })
            .then((r) => r.data)
        );

        const results = await Promise.all(extendedDataPromises);

        setPurchasedSubjects(results);
      } catch (error) {
        console.error('Ошибка при загрузке истории покупок:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchases();
  }, [user]);

  return (
    <div className="p-4 sm:p-6 max-w-full">
      <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 break-words">
        История покупок
      </h2>

      {loading ? (
        <p className="text-gray-500 text-sm sm:text-base">Загрузка...</p>
      ) : purchasedSubjects.length === 0 ? (
        <p className="text-gray-500 text-sm sm:text-base">
          У вас пока нет купленных объектов.
        </p>
      ) : (
        <div className="space-y-4 sm:space-y-6">
          {purchasedSubjects.map((subject) => (
            <PurchasedCard key={subject.id} subject={subject} />
          ))}
        </div>
      )}
    </div>
  );
}
