import React, { useState } from 'react';
import HistorySection from './HistorySection';
import DuplicatesSection from './DuplicatesSection';
import { ExternalLink } from 'lucide-react';
import SubjectImage from '../IMG/SubjectImage';


export default function PurchasedCard({ subject, setSubjects, onGlobalSubjectUpdate }) {
  const {
    id,
    name,
    adress,
    activ,
    link,
    price,
    creat_data,
    seller_type,
    seller_name,
    effective_year_built,
  } = subject;

  const statusEmoji = activ ? '✅ Активно' : '❌ Неактивно';
  const addrHtml = adress ? `📍 ${adress}` : '';

  const [showHistory, setShowHistory] = useState(false);
  const [showDuplicates, setShowDuplicates] = useState(false);


//   // допустим, у вас есть состояние, отвечающее за "открыта ли карточка"
//   const [isCardOpen, setIsCardOpen] = useState(true);

//   // Когда карточку открываем, меняем isCardOpen = true
//   const handleOpenCard = () => {
//     setIsCardOpen(true);
//   };

//   // Когда закрываем - false
//   const handleCloseCard = () => {
//     setIsCardOpen(false);
//   };

  const nameHtml = link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="text-sky-600 hover:underline inline-flex items-center gap-1"
    >
      {name || `Объект #${id}`}
      <ExternalLink size={16} className="inline-block text-sky-500" />
    </a>
  ) : (
    name || `Объект #${id}`
  );

  return (
    <div className="p-4 w-full bg-white rounded-xl shadow-md font-sans">
      {/* Верх: фото + name/адрес/статус (всегда горизонтально) */}
      <div className="flex mb-4">
        {/* Фото слева */}
        <div className="w-28 h-28 mr-4 shrink-0">
            
            {/* <SubjectImage subjectId={id} size={112} isOpen={isCardOpen} /> */}
            <SubjectImage subjectId={id} size={112} />
        </div>

        {/* Информация справа от фото */}
        <div className="flex flex-col justify-start flex-1">
          <div className="font-semibold text-sky-600 text-base sm:text-base mb-1">
            {nameHtml}
          </div>
          {addrHtml && (
            <div className="text-gray-500 text-xs sm:text-sm mb-1">
              {addrHtml}
            </div>
          )}
          <div className="text-gray-500 text-xs sm:text-sm mb-1">
            {statusEmoji}
          </div>
          <div className="text-gray-500 text-xs sm:text-sm">
            Создано: {creat_data ? new Date(creat_data).toLocaleDateString() : '—'}
          </div>
        </div>
      </div>

      {/* Кнопки: только на мобильных */}
      <div className="block sm:hidden mb-3">
        <div className="flex flex-col sm:flex-row gap-2">
          <button
            onClick={() => {
              setShowHistory(!showHistory);
              setShowDuplicates(false);
            }}
            className={`w-full px-3 py-1 border rounded-md text-xs
              ${
                showHistory
                  ? 'bg-sky-100 text-sky-700 border-sky-300'
                  : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'
              }`}
          >
            {showHistory ? 'Скрыть историю' : 'История'}
          </button>
          <button
            onClick={() => {
              setShowDuplicates(!showDuplicates);
              setShowHistory(false);
            }}
            className={`w-full px-3 py-1 border rounded-md text-xs
              ${
                showDuplicates
                  ? 'bg-sky-100 text-sky-700 border-sky-300'
                  : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'
              }`}
          >
            {showDuplicates ? 'Скрыть дубликаты' : 'Дубликаты'}
          </button>
        </div>

        {/* Мобильные выпадающие секции */}
        {showHistory && (
            <div
                className="absolute top-0 left-0 w-full h-full z-50 bg-white p-4 shadow-xl overflow-y-auto rounded-xl border border-gray-300 sm:hidden
                transition-all duration-300 ease-in-out transform translate-y-0 opacity-100"
            >
                <HistorySection subjectId={id} />
                <button
                onClick={() => setShowHistory(false)}
                className="mt-4 w-full py-2 bg-gray-100 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 text-sm"
                >
                Закрыть
                </button>
            </div>
            )}



            {showDuplicates && (
            <div
                className="absolute top-0 left-0 w-full h-full z-50 bg-white p-4 shadow-xl overflow-y-auto rounded-xl border border-gray-300 sm:hidden
                transition-all duration-300 ease-in-out transform translate-y-0 opacity-100"
            >
                <DuplicatesSection
                subjectId={id}
                setSubjects={setSubjects}
                onGlobalSubjectUpdate={onGlobalSubjectUpdate}
                />
                <button
                onClick={() => setShowDuplicates(false)}
                className="mt-4 w-full py-2 bg-gray-100 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 text-sm"
                >
                Закрыть
                </button>
            </div>
            )}



      </div>

      {/* Информация: сетка, адаптивна */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 text-xs sm:text-sm text-gray-700">
        <div>
          <span className="font-medium">💰 Цена:</span>{' '}
          {price ? `${price.toLocaleString()} ₽` : '—'}
        </div>

        <div>
          <span className="font-medium">👤 </span>{' '}
          {seller_type || '—'}
        </div>
        <div>
          <span className="font-medium">📛 Продавец:</span>{' '}
          {seller_name || '—'}
        </div>
        <div>
          <span className="font-medium">🏗 Год постройки:</span>{' '}
          {effective_year_built || '—'}
        </div>
      </div>

      {/* Кнопки + секции на десктопе */}
      <div className="hidden sm:flex justify-between gap-4 mt-4">
        <button
          onClick={() => setShowHistory(!showHistory)}
          className="flex-1 px-3 py-1 bg-gray-100 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 text-sm"
        >
          {showHistory ? 'Скрыть историю' : 'История'}
        </button>
        <button
          onClick={() => setShowDuplicates(!showDuplicates)}
          className="flex-1 px-3 py-1 bg-gray-100 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 text-sm"
        >
          {showDuplicates ? 'Скрыть дубликаты' : 'Дубликаты'}
        </button>
      </div>

      {/* Секции на десктопе под основной инфой */}
      <div className="hidden sm:block mt-3">
        {showHistory && <HistorySection subjectId={id} />}
        {showDuplicates && (
          <DuplicatesSection subjectId={id} setSubjects={setSubjects} onGlobalSubjectUpdate={onGlobalSubjectUpdate} />
        )}
      </div>
    </div>
  );
}
