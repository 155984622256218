import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import NoImagePlaceholder from './NoImagePlaceholder';
import ProxiedImage from './ProxiedImage';
import ImageLightbox from './ImageLightbox';

// export default function SubjectImage({ subjectId, size = 144, isOpen }) {
export default function SubjectImage({ subjectId, size = 144 }) {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subjectId}/img/`,
          {credentials: 'include',}
        );
        const data = await res.json();

        const local = Array.isArray(data.img_local_urls) ? data.img_local_urls : [];
        const remoteRaw = Array.isArray(data.links_img_array) ? data.links_img_array : [];
        const remote = remoteRaw.slice(0, 5);

        let selectedImages;
        if (local.length > 0) {
          selectedImages = local.map((src) => ({ src, isRemote: false }));
        } else if (remote.length > 0) {
          selectedImages = remote.map((src) => ({ src, isRemote: true }));
        } else {
          selectedImages = [];
        }
        // Больше не обрезаем изображения — все ссылки отображаются
        setImages(selectedImages);
      } catch (err) {
        console.error('Ошибка при загрузке изображения:', err);
      } finally {
        setIsLoading(false);
      }
    };

    
    // Если карточка "открыта" - загружаем
    // if (isOpen) {
    //   fetchImageData();
    // }
    fetchImageData();
  }, [subjectId]);
  // }, [subjectId, isOpen]);

  const showPrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const showNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, images.length - 1));
  };

  const openLightbox = () => setIsLightboxOpen(true);
  const closeLightbox = () => setIsLightboxOpen(false);

  const currentImage = images[currentIndex];

  return (
    <>
      <div
        className="relative flex items-center justify-center bg-gray-100 rounded-md overflow-hidden"
        style={{ width: size, height: size }}
      >
        {isLoading ? (
          <div className="text-gray-400 text-sm">Загрузка...</div>
        ) : images.length === 0 ? (
          <NoImagePlaceholder size={size} />
        ) : currentImage ? (
          currentImage.isRemote ? (
            <div onClick={openLightbox} className="cursor-pointer">
              <ProxiedImage src={currentImage.src} size={size} />
            </div>
          ) : (
            <img
              src={currentImage.src}
              alt="Фото"
              onClick={openLightbox}
              className="rounded-md object-contain cursor-pointer"
              style={{ width: size, height: size }}
            />
          )
        ) : null}

        {images.length > 1 && (
          <>
            {currentIndex > 0 && (
              <button
                onClick={showPrev}
                className="absolute left-1 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-100 p-1 rounded-full shadow"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
            )}
            {currentIndex < images.length - 1 && (
              <button
                onClick={showNext}
                className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-100 p-1 rounded-full shadow"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </>
        )}
      </div>

      {isLightboxOpen && (
        <ImageLightbox
          images={images}
          index={currentIndex}
          onClose={closeLightbox}
          onPrev={showPrev}
          onNext={showNext}
        />
      )}
    </>
  );
}
