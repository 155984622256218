// PointBalloon.jsx
import React from 'react';
import SubjectCard from './SubjectCard';

export default function PointBalloon({
  subjects,
  onClose,
  extendedDataMap,
  setExtendedDataMap,
  setSubjects,
  onGlobalSubjectUpdate
}) {
  if (!subjects?.length) return null;

  // Колбэк для обновления кэша расширенных данных
  const handleSubjectUpdate = (subjectId, updatedData) => {
    setExtendedDataMap((prev) => ({
      ...prev,
      [subjectId]: {
        ...prev[subjectId],
        ...updatedData,
      },
    }));
  };
  

  return (
    <div className="absolute right-2 top-2 w-full max-w-lg bg-white border border-gray-300 rounded-lg p-3 shadow-lg z-[9999]">
      <button
        className="float-right text-xl text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={onClose}
      >
        ×
      </button>
      {subjects.length > 1 && (
        <h4 className="mt-0 mb-2 text-sm font-semibold text-gray-700">
          Объектов: {subjects.length}
        </h4>
      )}
      <div className="max-h-[300px] overflow-y-auto space-y-3">
        {subjects.map((subj) => {
          const ext = extendedDataMap[subj.id] || {};
          const combined = { ...subj, ...ext };
          return (
            <div key={subj.id} className="border-b border-gray-100 pb-2 w-full">
              <SubjectCard subject={combined}
              onSubjectUpdate={handleSubjectUpdate} 
              setSubjects={setSubjects}
              onGlobalSubjectUpdate={onGlobalSubjectUpdate}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
