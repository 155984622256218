// ObjectDetailsList.jsx
import React from 'react';
import SubjectCard from './SubjectCardList';

export default function ObjectDetailsList({ subject, extendedData, setSubjects, onGlobalSubjectUpdate }) {
  // Объединяем subject + extendedData
  const combined = { ...subject, ...extendedData };

  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        padding: '16px',
        // можно добавить фон / границы по желанию
      }}
    >
      <SubjectCard subject={combined} setSubjects={setSubjects} onGlobalSubjectUpdate={onGlobalSubjectUpdate} />
    </div>
  );
}
