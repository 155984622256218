// src/components/VerificationForm.jsx
import React from 'react';

export default function VerificationForm({
  email,
  verificationCode, setVerificationCode,
  errorMessage, infoMessage,
  onVerify
}) {
  return (
    <form onSubmit={onVerify} className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Подтверждение кода</h2>

      {errorMessage && (
        <div className="bg-red-100 text-red-700 p-2 rounded">
          {errorMessage}
        </div>
      )}
      {infoMessage && (
        <div className="bg-green-100 text-green-700 p-2 rounded">
          {infoMessage}
        </div>
      )}

      <p className="text-sm text-gray-600">
        На адрес <b>{email}</b> отправлен код подтверждения.
      </p>

      <div>
        <label className="block font-medium mb-1">
          Код из письма
        </label>
        <input
          type="text"
          className="w-full border px-3 py-2 rounded"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />
      </div>

      <button
        type="submit"
        className="w-full bg-green-500 text-white py-2 rounded font-semibold hover:bg-green-600"
      >
        Подтвердить
      </button>
    </form>
  );
}