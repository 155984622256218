import React, { useState, useEffect } from 'react';
import HistorySection from '../MAPS/HistorySection';
import DuplicatesSection from '../MAPS/DuplicatesSection';
import SubjectImage from '../IMG/SubjectImage';

import {
  ExternalLink,
  Clock,
  CopyCheck,
  CheckCircle,
  XCircle,
  MapPin,
  X,
} from 'lucide-react';

export default function PurchasedCard({ subject, setSubjects, onGlobalSubjectUpdate }) {
  const {
    id,
    name,
    adress,
    activ,
    link,
    price,
    creat_data,
    seller_type,
    seller_name,
    effective_year_built,
  } = subject;

  const [activeTab, setActiveTab] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // На десктопе по умолчанию показываем "history"
  useEffect(() => {
    if (!isMobile) {
      setActiveTab('history');
    } else {
      setActiveTab(null);
    }
  }, [isMobile]);

  const nameHtml = link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="text-sky-600 hover:underline inline-flex items-center gap-1"
    >
      {name || `Объект #${id}`}
      <ExternalLink size={16} className="inline-block text-sky-500" />
    </a>
  ) : (
    name || `Объект #${id}`
  );

  const status = activ ? (
    <span className="text-emerald-600 flex items-center text-sm">
      <CheckCircle className="w-4 h-4 mr-1" /> Активно
    </span>
  ) : (
    <span className="text-red-500 flex items-center text-sm">
      <XCircle className="w-4 h-4 mr-1" /> Неактивно
    </span>
  );

  return (
    <div className="relative p-4 bg-white rounded-xl shadow-md font-sans w-full h-auto min-h-[325px] flex flex-col md:flex-row gap-4 overflow-hidden">
      {/* Фото */}
      <div className="w-full md:w-[300px] h-[200px] md:h-[325px] flex-shrink-0 flex flex-col items-center justify-center gap-2">
      <SubjectImage subjectId={id} size={isMobile ? 200 : 300} />


        {/* Мобильные кнопки под фото */}
        {isMobile && (
          <div className="flex w-full gap-2 mt-4 mb-8">
            <button
              onClick={() => setActiveTab('history')}
              className="flex-1 flex items-center justify-center gap-1 px-3 py-1 text-sm rounded-md border bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200"
            >
              <Clock className="w-4 h-4" />
              История
            </button>
            <button
              onClick={() => setActiveTab('duplicates')}
              className="flex-1 flex items-center justify-center gap-1 px-3 py-1 text-sm rounded-md border bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200"
            >
              <CopyCheck className="w-4 h-4" />
              Дубликаты
            </button>
          </div>
        )}
      </div>

      {/* Центральный блок: инфа */}
      <div className="flex-1 min-w-[250px] flex flex-col justify-between">
        <div>
          <h2 className="font-semibold text-lg text-gray-900 mb-1">{nameHtml}</h2>

          {adress && (
            <div className="text-sm text-gray-500 flex items-center mb-2">
              <MapPin className="w-4 h-4 mr-1" />
              {adress}
            </div>
          )}

          <div className="text-sm mb-3">{status}</div>

          {/* <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm text-gray-700"> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 text-sm text-gray-700 mt-4">
            <div>
              <span className="font-medium">💰 Цена:</span>{' '}
              {price ? `${price.toLocaleString()} ₽` : '—'}
            </div>
            <div>
              <span className="font-medium">👤 </span>{' '}
              {seller_type || '—'}
            </div>
            <div>
              <span className="font-medium">📛 Продавец:</span>{' '}
              {seller_name || '—'}
            </div>
            <div>
              <span className="font-medium">🏗 Год постройки:</span>{' '}
              {effective_year_built || '—'}
            </div>
          </div>
        </div>

        <div className="text-sm text-gray-400 mt-3 md:mt-0">
          Создано:{' '}
          {creat_data ? new Date(creat_data).toLocaleDateString() : '—'}
        </div>
      </div>

      {/* Правая часть — только на десктопе */}
      {!isMobile && (
        <div className="w-[380px] flex flex-col">
          <div className="flex gap-2 mb-2">
            <button
              onClick={() => setActiveTab('history')}
              className={`flex-1 flex items-center justify-center gap-1 px-3 py-1 text-sm rounded-md border transition-all
                ${
                  activeTab === 'history'
                    ? 'bg-sky-100 text-sky-700 border-sky-300'
                    : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'
                }`}
            >
              <Clock className="w-4 h-4" />
              История
            </button>
            <button
              onClick={() => setActiveTab('duplicates')}
              className={`flex-1 flex items-center justify-center gap-1 px-3 py-1 text-sm rounded-md border transition-all
                ${
                  activeTab === 'duplicates'
                    ? 'bg-sky-100 text-sky-700 border-sky-300'
                    : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'
                }`}
            >
              <CopyCheck className="w-4 h-4" />
              Дубликаты
            </button>
          </div>

          <div className="flex-1 max-h-[285px] overflow-y-auto rounded-md bg-gray-50 border border-gray-200 p-2 shadow-inner">
            {activeTab === 'history' && <HistorySection subjectId={id} />}
            {activeTab === 'duplicates' && (
              <DuplicatesSection subjectId={id} setSubjects={setSubjects} onGlobalSubjectUpdate={onGlobalSubjectUpdate} />
            )}
          </div>
        </div>
      )}

      {/* Мобильный оверлей поверх */}
      {isMobile && activeTab && (
        <div className="absolute top-0 left-0 w-full h-full bg-white z-20 p-4 shadow-xl overflow-y-auto rounded-xl">
          <div className="flex justify-between items-center mb-3">
            <div className="text-lg font-semibold text-gray-800">
              {activeTab === 'history' ? 'История' : 'Дубликаты'}
            </div>
            <button
              onClick={() => setActiveTab(null)}
              className="text-gray-500 hover:text-gray-800"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {activeTab === 'history' && <HistorySection subjectId={id} />}
          {activeTab === 'duplicates' && (
            <DuplicatesSection
              subjectId={id}
              setSubjects={setSubjects}
              onGlobalSubjectUpdate={onGlobalSubjectUpdate}
            />
          )}

        </div>
      )}
    </div>
  );
}
