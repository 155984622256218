import React, { useState, useEffect, useCallback, useRef } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import supercluster from 'supercluster';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

import PointBalloon from './PointBalloon';

function parseCoords(str) {
  if (!str) return null;
  const parts = str.split(' ');
  if (parts.length < 2) return null;
  const lat = parseFloat(parts[0]);
  const lon = parseFloat(parts[1]);
  if (Number.isNaN(lat) || Number.isNaN(lon)) return null;
  return [lat, lon];
}

function getCityCenter(cityKey) {
  switch (cityKey) {
    case 'kirov':
      return [58.603531, 49.668004];
    case 'irkutsk':
      return [52.286387, 104.28066];
    default:
      return [52.286387, 104.28066];
  }
}

export default function YandexMapSuperCluster({
  subjects = [],
  setSubjects,
  selectedCity = 'irkutsk',
  isLoading = false,
  isCRM = false,
  user = null,
  onGlobalSubjectUpdate
}) {
  const baseURL = process.env.REACT_APP_API_URL;
  const mapRef = useRef(null);

  // Параметры карты
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState(getCityCenter(selectedCity));

  // supercluster
  const [clusterIndex, setClusterIndex] = useState(null);
  const [clusters, setClusters] = useState([]);

  // Состояние балуна
  const [showPointBalloon, setShowPointBalloon] = useState(false);
  const [pointBalloonSubjects, setPointBalloonSubjects] = useState([]);
  
  // NEW: сохраняем координаты, где открылся балун, чтобы пересчитывать
  const [balloonCoords, setBalloonCoords] = useState(null);

  // Кэш "расширенных" данных
  const [extendedDataMap, setExtendedDataMap] = useState({});

  // ---------------------------
  // 1) Создаём supercluster
  // ---------------------------
  useEffect(() => {
    if (!subjects.length) {
      setClusterIndex(null);
      setClusters([]);
      return;
    }
    const features = [];
    subjects.forEach((s) => {
      const c = parseCoords(s.effective_coordinates);
      if (!c) return;
      const [lat, lon] = c;
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon, lat], // supercluster expects [lng, lat]
        },
        properties: {
          id: s.id,
          name: s.name,
          adress: s.adress,
          purchased: s.purchased,
          activ: s.activ,
          link: s.link,
          has_photos: s.has_photos,
          history_counts: s.history_counts,
          duplicates_count: s.duplicates_count,
        },
      });
    });

    const sc = new supercluster({
      radius: 60,
      maxZoom: 17,
    });
    sc.load(features);
    setClusterIndex(sc);
  }, [subjects]);

  // ---------------------------
  // 2) Пересчёт кластеров
  // ---------------------------
  const recalcClusters = useCallback(
    (bounds, z) => {
      if (!clusterIndex) return;
      const [[southLat, westLng], [northLat, eastLng]] = bounds;
      const newClusters = clusterIndex.getClusters(
        [westLng, southLat, eastLng, northLat],
        z
      );
      setClusters(newClusters);
    },
    [clusterIndex]
  );

  // ---------------------------
  // 3) handleMapInstance
  // ---------------------------
  const handleMapInstance = useCallback(
    (ref) => {
      if (ref && !mapRef.current) {
        mapRef.current = ref;
        if (clusterIndex) {
          const b = ref.getBounds();
          const z = ref.getZoom();
          recalcClusters(b, z);
        }
      }
    },
    [clusterIndex, recalcClusters]
  );

  // ---------------------------
  // 4) handleBoundsChange
  // ---------------------------
  const handleBoundsChange = useCallback(() => {
    if (!mapRef.current) return;
    const b = mapRef.current.getBounds();
    const z = mapRef.current.getZoom();
    setZoom(z);
    setCenter(mapRef.current.getCenter());
    if (clusterIndex) {
      recalcClusters(b, z);
    }
    // если хотим закрывать балун при перетаскивании - раскомментировать:
    // setShowPointBalloon(false);
  }, [clusterIndex, recalcClusters]);

  // ---------------------------
  // 5) Если clusterIndex изменился
  // ---------------------------
  useEffect(() => {
    if (mapRef.current && clusterIndex) {
      const b = mapRef.current.getBounds();
      const z = mapRef.current.getZoom();
      recalcClusters(b, z);
    }
  }, [clusterIndex, recalcClusters]);

  // ---------------------------
  // 6) Загрузка расширенных
  // ---------------------------
  const fetchExtendedDataIfNeeded = useCallback(
    async (subjectId) => {
      if (extendedDataMap[subjectId]) return; // уже в кэше
      try {
        const [ext, about] = await Promise.all([
          axios.get(`${baseURL}/dashboard/api/public/subject/${subjectId}/extended/`, {
            withCredentials: true,
          }),
          axios.get(`${baseURL}/dashboard/api/public/subject/${subjectId}/extended_about/`, {
            withCredentials: true,
          }),
        ]);
        const combined = { ...ext.data, ...about.data };
        setExtendedDataMap((prev) => ({ ...prev, [subjectId]: combined }));
      } catch (e) {
        console.error(`Ошибка при загрузке расширенных данных для #${subjectId}`, e);
      }
    },
    [baseURL, extendedDataMap]
  );

  // ---------------------------
  // 7) Клик по кластеру
  // ---------------------------
  const handleClusterClick = useCallback(
    (feat) => {
      if (!clusterIndex || !mapRef.current) return;
      const expansionZoom = clusterIndex.getClusterExpansionZoom(feat.id);
      const [lon, lat] = feat.geometry.coordinates;
      const finalZoom = expansionZoom > 19 ? 19 : expansionZoom;
      setZoom(finalZoom);
      setCenter([lat, lon]);
    },
    [clusterIndex]
  );

  // ---------------------------
  // 8) Клик по одиночной точке
  // ---------------------------
  const handlePointClick = useCallback(
    (feat) => {
      if (!mapRef.current) return;
      const [lon, lat] = feat.geometry.coordinates;

      // Сохраняем coords балуна
      setBalloonCoords([lon, lat]);

      // собираем все subjects с такими же координатами
      const sameSubs = subjects.filter((s) => {
        const c = parseCoords(s.effective_coordinates);
        if (!c) return false;
        return Math.abs(c[0] - lat) < 1e-9 && Math.abs(c[1] - lon) < 1e-9;
      });

      setPointBalloonSubjects(sameSubs);
      setShowPointBalloon(true);
    },
    [subjects]
  );

  // ---------------------------
  // 9) pointBalloonSubjects: пересчёт при изменении subjects
  // ---------------------------
  useEffect(() => {
    if (!showPointBalloon || !balloonCoords) return;
    // Пересчитываем те же объекты по координатам
    const [lon, lat] = balloonCoords;
    const updatedSubs = subjects.filter((s) => {
      const c = parseCoords(s.effective_coordinates);
      if (!c) return false;
      return Math.abs(c[0] - lat) < 1e-9 && Math.abs(c[1] - lon) < 1e-9;
    });
    setPointBalloonSubjects(updatedSubs);
  }, [subjects, balloonCoords, showPointBalloon]);

  // ---------------------------
  // Когда pointBalloonSubjects меняется -> грузим расширенные
  // ---------------------------
  useEffect(() => {
    pointBalloonSubjects.forEach((subj) => {
      fetchExtendedDataIfNeeded(subj.id);
    });
  }, [pointBalloonSubjects, fetchExtendedDataIfNeeded]);

  // ---------------------------
  // Render
  // ---------------------------
  return (
    <div style={{ position: 'relative', width: '100%', height: '600px' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(255,255,255,0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <YMaps>
        <Map
          width="100%"
          height="100%"
          state={{ center, zoom }}
          instanceRef={handleMapInstance}
          onBoundsChange={handleBoundsChange}
        >
          {clusters.map((feat, idx) => {
            const [lon, lat] = feat.geometry.coordinates;
            const isCluster = !!feat.properties.cluster;

            if (isCluster) {
              const pointCount = feat.properties.point_count;
              return (
                <Placemark
                  key={`cluster-${feat.id}-${idx}`}
                  geometry={[lat, lon]}
                  options={{ preset: 'islands#yellowCircleIcon' }}
                  properties={{ hintContent: `Кластер (${pointCount})` }}
                  onClick={() => handleClusterClick(feat)}
                />
              );
            } else {
              // одиночная точка
              const isActive = feat.properties.activ;
              return (
                <Placemark
                  key={`point-${feat.properties.id}-${idx}`}
                  geometry={[lat, lon]}
                  options={{
                    preset: feat.properties.purchased
                      ? 'islands#greenCircleIcon'
                      : isActive
                      ? 'islands#blueCircleIcon'
                      : 'islands#grayCircleIcon',
                  }}
                  properties={{
                    hintContent: feat.properties.name || `ID:${feat.properties.id}`,
                  }}
                  onClick={() => handlePointClick(feat)}
                />
              );
            }
          })}
        </Map>
      </YMaps>

      {showPointBalloon && pointBalloonSubjects.length > 0 && (
        <PointBalloon
          subjects={pointBalloonSubjects}
          onClose={() => setShowPointBalloon(false)}
          extendedDataMap={extendedDataMap}
          setExtendedDataMap={setExtendedDataMap}
          setSubjects={setSubjects}
          onGlobalSubjectUpdate={onGlobalSubjectUpdate}
        />
      )}
    </div>
  );
}
