import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import ProxiedImage from './ProxiedImage';

export default function ImageLightbox({ images, index, onClose, onPrev, onNext }) {
  const currentImage = images[index];

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
      if (e.key === 'ArrowLeft') onPrev();
      if (e.key === 'ArrowRight') onNext();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onPrev, onNext]);

  const content = (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-[9999]">
      <button
        className="absolute top-4 right-4 text-white hover:text-gray-300"
        onClick={onClose}
      >
        <X className="w-6 h-6" />
      </button>

      {index > 0 && (
        <button
          onClick={onPrev}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
        >
          <ChevronLeft className="w-8 h-8" />
        </button>
      )}

      {currentImage && (
        currentImage.isRemote ? (
          <ProxiedImage src={currentImage.src} size={800} />
        ) : (
          <img
            src={currentImage.src}
            alt="Фото"
            className="max-h-[90vh] max-w-[90vw] object-contain rounded-md shadow-xl"
          />
        )
      )}

      {index < images.length - 1 && (
        <button
          onClick={onNext}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-300"
        >
          <ChevronRight className="w-8 h-8" />
        </button>
      )}
    </div>
  );

  return ReactDOM.createPortal(content, document.body);
}
