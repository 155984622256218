// src/components/RegistrationFormStep1.jsx
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';

export default function RegistrationFormStep1({
  username, setUsername,
  email, setEmail,
  phone, setPhone,
  password, setPassword,
  password2, setPassword2,
  personalDataAgreement, setPersonalDataAgreement,
  newsletterAgreement, setNewsletterAgreement,
  termsAgreement, setTermsAgreement,
  role, setRole,
  selectedCities, setSelectedCities,

  errorMessage, setErrorMessage,
  infoMessage, setInfoMessage,
  onSubmit,
}) {
  const [cities, setCities] = useState([]);

  // Подгружаем список городов (GET /dashboard/api/public/cities/)
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/dashboard/api/public/cities/`)
      .then((res) => {
        // API отдаёт { results: [...] }, берём results
        setCities(res.data.results);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Вместо multiple <select>, теперь одинарный
  const handleSelectCity = (e) => {
    const cityId = e.target.value;
    setSelectedCities([cityId]); 
    // Храним как массив, но только один город в массиве
  };

  /**
   * Простейший прогресс: считаем обязательные поля:
   * username, email, phone, password, password2, role (?), personalDataAgreement, termsAgreement
   * (newsletterAgreement - не обязательный)
   */
  const requiredFieldsFilled = useMemo(() => {
    let count = 0;
    if (username.trim()) count++;
    if (email.trim()) count++;
    if (phone.trim()) count++;
    if (password.trim()) count++;
    if (password2.trim()) count++;
    // Город (selectedCities[0]?)
    if (selectedCities && selectedCities[0]) count++;
    // Роль
    if (role) count++;
    // Галочки
    if (personalDataAgreement) count++;
    if (termsAgreement) count++;
    // Итого считаем, что 9 обязательных пунктов

    return count;
  }, [
    username, email, phone, password, password2,
    selectedCities, role,
    personalDataAgreement, termsAgreement
  ]);

  // Всего 9 обязательных пунктов (вы можете изменить логику по желанию)
  const progressPercentage = Math.round((requiredFieldsFilled / 9) * 100);

  // Переопределим onSubmit, чтобы сначала проверить галочки.
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Сразу сбросим ошибки, info
    setErrorMessage('');
    setInfoMessage('');

    // Если любые обязательные галочки не стоят
    if (!personalDataAgreement || !termsAgreement) {
      setErrorMessage('Пожалуйста, согласитесь со всеми обязательными условиями.');
      return;
    }

    // Дальше вызываем родительский onSubmit
    onSubmit(e);
  };

  return (
    <form onSubmit={handleFormSubmit} className="space-y-4">
      {/* Заголовок и подзаголовок */}
      <div className="mb-4 text-center">
        <h2 className="text-2xl font-bold">Регистрация</h2>
        <p className="text-gray-600 mt-1">Добро пожаловать! Заполните поля ниже, чтобы начать.</p>
      </div>

      {/* Прогресс-бар */}
      <div className="w-full bg-gray-200 h-2 rounded">
        <div
          className="bg-green-500 h-2 rounded"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
      <p className="text-sm text-right text-gray-500">{progressPercentage}%</p>

      {errorMessage && (
        <div className="bg-red-100 text-red-700 p-2 rounded">
          {errorMessage}
        </div>
      )}
      {infoMessage && (
        <div className="bg-green-100 text-green-700 p-2 rounded">
          {infoMessage}
        </div>
      )}

      {/* Логин */}
      <div>
        <label className="block font-medium mb-1">
          Имя пользователя (логин)
        </label>
        <input
          type="text"
          placeholder="Например, Ivan123"
          className="w-full border px-3 py-2 rounded"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>

      {/* E-mail */}
      <div>
        <label className="block font-medium mb-1">E-mail</label>
        <input
          type="email"
          placeholder="example@mail.com"
          className="w-full border px-3 py-2 rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      {/* Телефон (InputMask) */}
      <div>
        <label className="block font-medium mb-1">Телефон</label>
        <InputMask
          mask="+7 (999) 999-99-99"
          maskPlaceholder="+7 (___) ___-__-__"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="text"
              className="w-full border px-3 py-2 rounded"
              required
            />
          )}
        </InputMask>
      </div>

      {/* Пароль */}
      <div>
        <label className="block font-medium mb-1">Пароль</label>
        <input
          type="password"
          placeholder="Пароль..."
          className="w-full border px-3 py-2 rounded"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      {/* Повтор пароля */}
      <div>
        <label className="block font-medium mb-1">Повторите пароль</label>
        <input
          type="password"
          placeholder="Ещё раз..."
          className="w-full border px-3 py-2 rounded"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          required
        />
      </div>

      {/* Роль */}
      <div>
        <label className="block font-medium mb-1">Ваша роль</label>
        <select
          className="w-full border px-3 py-2 rounded"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="private">Частное лицо</option>
          <option value="private_realtor">Частный риелтор</option>
          <option value="agency_agent">Агент в агентстве</option>
          <option value="agency_owner">Владелец агентства</option>
        </select>
      </div>

      {/* Выбор одного города */}
      <div>
        <label className="block font-medium mb-1">Город</label>
        <select
          className="w-full border px-3 py-2 rounded"
          value={selectedCities[0] || ''} // берём первый элемент как текущий
          onChange={handleSelectCity}
          required
        >
          <option value="" disabled>-- Выберите город --</option>
          {cities.map(city => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>

      {/* Галочка – согласие на обработку ПД (обязательная) */}
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="personalData"
          checked={personalDataAgreement}
          onChange={(e) => setPersonalDataAgreement(e.target.checked)}
          // required — мы отдельно проверяем, так что можно убрать
        />
        <label htmlFor="personalData" className="text-sm">
          Я даю согласие на обработку моих персональных данных
        </label>
      </div>

      {/* Галочка – рассылка новостей (необязательная) */}
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="newsletter"
          checked={newsletterAgreement}
          onChange={(e) => setNewsletterAgreement(e.target.checked)}
        />
        <label htmlFor="newsletter" className="text-sm">
          Хочу получать новости и акции
        </label>
      </div>

      {/* Галочка – правила сервиса (обязательная) */}
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="terms"
          checked={termsAgreement}
          onChange={(e) => setTermsAgreement(e.target.checked)}
        />
        <label htmlFor="terms" className="text-sm">
          Я принимаю <a href="/terms" className="underline text-blue-600" target="_blank" rel="noreferrer">правила сервиса</a>
        </label>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 rounded font-semibold hover:bg-blue-600"
      >
        Зарегистрироваться
      </button>
    </form>
  );
}