import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useAuth } from '../AUTH/AuthProvider';

export default function PurchaseButton({
  subjectId,
  onPurchaseSuccess,          // локальный колбэк (для, например, обновления dups)
  setSubjects,                // если нужно сразу поменять массив в текущем компоненте
  onGlobalSubjectUpdate,      // новый колбэк для "общего" апдейта (обновить все ссылки/списки)
}) {
  const [loading, setLoading] = useState(false);
  const csrftoken = Cookies.get('csrftoken');
  const { setUser } = useAuth(); // чтобы обновить баланс

  const handlePurchase = async () => {
    setLoading(true);
    try {
      // 1) Отправляем POST на покупку
      const purchaseUrl = `${process.env.REACT_APP_API_URL}/api/public/subject/${subjectId}/purchase/`;
      const purchaseResp = await axios.post(
        purchaseUrl,
        {},
        {
          withCredentials: true,
          headers: { 'X-CSRFToken': csrftoken },
        }
      );
      if (!purchaseResp.data || purchaseResp.data.purchased !== true) {
        alert(purchaseResp.data?.detail || 'Не удалось купить объект');
        return;
      }

      // 2) Обновляем баланс пользователя (если сервер его вернул)
      const newBalance = purchaseResp.data.balance;
      if (newBalance !== undefined) {
        setUser((prevUser) => ({
          ...prevUser,
          balance: newBalance,
        }));
      }

      // 3) Получаем "основные" данные из /subject-public/:id/
      const detailUrl = `${process.env.REACT_APP_API_URL}/dashboard/api/subject-public/${subjectId}/`;
      const detailResp = await axios.get(detailUrl, {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrftoken },
      });
      const updatedSubject = detailResp.data;

      // 4) Запрашиваем extended_about (но НЕ extended).
      //    Вы говорили убрать запрос к extended, так и сделаем.
      const aboutUrl = `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subjectId}/extended_about/`;
      let aboutData = {};
      try {
        const aboutResp = await axios.get(aboutUrl, { withCredentials: true });
        aboutData = aboutResp.data;
      } catch (aboutErr) {
        console.warn('extended_about не смогли загрузить:', aboutErr);
      }

      // 5) Собираем финальный объект
      const finalUpdatedSubject = {
        ...updatedSubject,
        ...aboutData,
      };

      // 6) Локальный колбэк (например, для обновления
      //    дубликатов в DuplicatesSection и т.д.)
      if (onPurchaseSuccess) {
        onPurchaseSuccess(subjectId, finalUpdatedSubject);
      }

      // 7) Обновляем локальный массив subjects (если он тут есть)
      if (setSubjects) {
        setSubjects((prevSubjects) =>
          prevSubjects.map((sub) =>
            sub.id === subjectId ? { ...sub, ...finalUpdatedSubject } : sub
          )
        );
      }

      // 8) Новый колбэк для "глобального" апдейта:
      //    Если где-то ещё в приложении хранится этот же subjectId
      //    (например, в списке, на карте, в других дубликатах),
      //    то "глобальный" колбэк обновит их тоже.
      if (onGlobalSubjectUpdate) {
        // console.log('[PurchaseButton] Вызов onGlobalSubjectUpdate', subjectId);
        onGlobalSubjectUpdate(subjectId, finalUpdatedSubject);
      }
    } catch (error) {
      console.error('Ошибка при покупке:', error);
      if (error.response) {
        alert(error.response.data?.detail || 'Ошибка при покупке');
      } else {
        alert('Произошла ошибка при покупке');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handlePurchase}
      disabled={loading}
      className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm mt-2"
    >
      {loading ? 'Обрабатываю...' : 'Получить данные'}
    </button>
  );
}
