import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import PurchaseButton from './PurchaseButton';
import HistorySection from './HistorySection';
import SubjectImage from '../IMG/SubjectImage';

export default function DuplicatesSection({ subjectId, setSubjects, onGlobalSubjectUpdate }) {
  const [fetchedDuplicates, setFetchedDuplicates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedMap, setExpandedMap] = useState({});

  const fetchDuplicates = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/api/container/${subjectId}/`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await response.json();
      setFetchedDuplicates(data);
    } catch (err) {
      console.error('Ошибка при загрузке дубликатов:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDuplicates();
  }, [subjectId]);

  const handleExpand = (id) => {
    setExpandedMap((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handlePurchaseUpdate = (subjectId, updatedData) => {
    setFetchedDuplicates((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        results: prev.results.map((item) =>
          item.id === subjectId ? { ...item, ...updatedData } : item
        ),
      };
    });
  };

  if (loading) {
    return <p className="text-sm text-gray-500 mt-2">Загрузка дубликатов...</p>;
  }

  if (!fetchedDuplicates || fetchedDuplicates.count === 0) {
    return <p className="text-sm text-gray-500 mt-2">Дубликаты не определены</p>;
  }

  return (
    <div className="mt-4 border-t pt-4 border-gray-200 space-y-4 w-full">
      <h4 className="text-sm font-semibold text-gray-700 mb-2">Дубликаты</h4>
      {fetchedDuplicates.results.map((dup) => {
        const isExpanded = expandedMap[dup.id] || false;
        const finalPrice = dup.price ?? dup.ext?.price;
        const sellerType = dup.seller_type ?? dup.seller?.seller_type_class;
        const sellerName = dup.seller_name ?? dup.seller?.seller_name_class;

        const status = dup.activ ? (
          <span className="text-emerald-600 flex items-center text-xs sm:text-sm">
            <CheckCircle className="w-4 h-4 mr-1" /> Активно
          </span>
        ) : (
          <span className="text-red-500 flex items-center text-xs sm:text-sm">
            <XCircle className="w-4 h-4 mr-1" /> Неактивно
          </span>
        );

        const nameHtml = dup.link ? (
          <a
            href={dup.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline text-xs sm:text-sm font-medium"
          >
            {dup.name || `Объект #${dup.id}`}
          </a>
        ) : (
          <span className="text-xs sm:text-sm font-medium">
            {dup.name || `Объект #${dup.id}`}
          </span>
        );

        return (
          <div
            key={dup.id}
            className="p-3 border border-gray-200 rounded-lg bg-gray-50 w-full"
          >
            {/* Верх: картинка + name/status/date */}
            <div className="flex flex-row gap-3 items-start">
              {/* Картинка (адаптивный размер) */}
              <div className="shrink-0">
                <SubjectImage key={`subject-image-${dup.id}-${dup.link ? 'purchased' : 'not-purchased'}`} subjectId={dup.id} size={72} /> {/* меньше на мобилках */}
              </div>

              {/* Название / Статус / Дата */}
              <div className="flex flex-col justify-between flex-1 gap-y-2 sm:gap-y-1.5 mt-1">
                {nameHtml}
                {status}
                <div className="text-xs sm:text-sm text-gray-600">
                  🕒 Создан: {dup.creat_data ? new Date(dup.creat_data).toLocaleDateString() : '—'}
                </div>
              </div>
            </div>

            {/* Остальная информация — на всю ширину */}
{/* Остальная информация — на всю ширину */}
<div className="mt-4 space-y-2 text-xs sm:text-sm">
  {finalPrice && (
    <div className="text-gray-600">
      💰 Цена: {finalPrice.toLocaleString()} ₽
    </div>
  )}

  {/* Только для купленных — тип и имя продавца */}
  {dup.link && sellerType && (
    <div className="text-gray-600">
      👤 <span className="font-medium">Тип продавца:</span> {sellerType}
    </div>
  )}
  {dup.link && sellerName && (
    <div className="text-gray-600">
      📛 <span className="font-medium">Имя продавца:</span> {sellerName}
    </div>
  )}

  {/* Только для некупленных — тип продавца + кнопка */}
  {!dup.link && (
    <div className="space-y-2">
      {sellerType && (
        <div className="text-gray-600">
          👤 <span className="font-medium">Тип продавца:</span> {sellerType}
        </div>
      )}

      <PurchaseButton
        subjectId={dup.id}
        onPurchaseSuccess={handlePurchaseUpdate}
        setSubjects={setSubjects}
        onGlobalSubjectUpdate={onGlobalSubjectUpdate}
      />
    </div>
  )}

  {/* История (только если куплен) */}
  {dup.link && (
    <div>
      <button
        onClick={() => handleExpand(dup.id)}
        className="px-3 py-1 bg-gray-100 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-200 text-sm"
      >
        {isExpanded ? 'Скрыть историю' : 'Показать историю'}
      </button>
      {isExpanded && (
        <div className="mt-3">
          <HistorySection subjectId={dup.id} />
        </div>
      )}
    </div>
  )}
</div>


          </div>
        );
      })}
    </div>
  );
}
