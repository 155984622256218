import React from 'react';
import {
  TextField,
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  IconButton, 
  InputAdornment
} from '@mui/material';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaUser, FaBuilding } from 'react-icons/fa';
import RoomsAutocomplete from './RoomsAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';

const CommonFilterForm = ({
  searchTerm,
  setSearchTerm,
  selectedRooms,
  setSelectedRooms,
  activeStatuses,
  setActiveStatuses,
  sellerTypes,
  setSellerTypes,
  tempPriceFrom,
  setTempPriceFrom,
  tempPriceTo,
  setTempPriceTo,
  tempFloorFrom,
  setTempFloorFrom,
  tempFloorTo,
  setTempFloorTo,
  tempYearBuiltFrom,
  setTempYearBuiltFrom,
  tempYearBuiltTo,
  setTempYearBuiltTo,
  isCRM,
}) => {
  const roomsOptions = ['Студия', '1', '2', '3', '4', '5'];

  const handleToggleActive = (event, newActive) => {
    setActiveStatuses(newActive);
  };

  const handleToggleSeller = (event, newSeller) => {
    // В режиме exclusive, если newSeller равен null (т.е. пользователь снял выделение),
    // устанавливаем значение по умолчанию – "Все"
    setSellerTypes(newSeller !== null ? [newSeller] : ['Все']);
  };

  const handleSearchTermChange = (e) => {
    let value = e.target.value;
    const contextIndex = value.indexOf('?context');
    if (contextIndex !== -1) {
      value = value.substring(0, contextIndex);
    }
    setSearchTerm(value);
  };

  const handleRoomsChange = (newSelectedRooms) => {
    setSelectedRooms(newSelectedRooms);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 3,
        mt: 3,
        mb: 1,
        width: '100%',
      }}
    >
<Box>
  <ToggleButtonGroup
    exclusive
    value={activeStatuses[0] || 'Все'}
    onChange={(event, newActive) =>
      setActiveStatuses(newActive !== null ? [newActive] : ['Все'])
    }
    aria-label="active status"
    color="primary"
    size="small"
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
    }}
  >
    <ToggleButton value="Активно" aria-label="Активно">
      <AiOutlineCheckCircle style={{ marginRight: 4 }} />
      Объявление активно
    </ToggleButton>
    <ToggleButton value="Неактивно" aria-label="Неактивно">
      <AiOutlineCloseCircle style={{ marginRight: 4 }} />
      Объявление неактивно
    </ToggleButton>
    <ToggleButton value="Все" aria-label="Все">
      ВСЕ
    </ToggleButton>
  </ToggleButtonGroup>
</Box>


<Box>
  <ToggleButtonGroup
    exclusive
    value={sellerTypes[0] || 'Все'}
    onChange={handleToggleSeller}
    aria-label="seller types"
    color="secondary"
    size="small"
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
    }}
  >
    <ToggleButton value="Все" aria-label="all">
      Продавец: Все
    </ToggleButton>
    <ToggleButton value="Частное лицо" aria-label="private">
      <FaUser style={{ marginRight: 4 }} />
      Частное лицо
    </ToggleButton>
    <ToggleButton value="Агентство" aria-label="agency">
      <FaBuilding style={{ marginRight: 4 }} />
      Агентство
    </ToggleButton>
  </ToggleButtonGroup>
</Box>

      {/* Если isCRM === true, отображаем блок с ценой */}
      {isCRM && (
        <Box>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Цена от"
              value={tempPriceFrom}
              onChange={(e) => setTempPriceFrom(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ mr: 1 }}
            />
            <TextField
              size="small"
              label="Цена до"
              value={tempPriceTo}
              onChange={(e) => setTempPriceTo(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Box>
        </Box>
      )}

      <Box>
        <Box display="flex" alignItems="center">
          <TextField
            size="small"
            label="Этаж от"
            value={tempFloorFrom}
            onChange={(e) => setTempFloorFrom(e.target.value)}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ mr: 1 }}
          />
          <TextField
            size="small"
            label="Этаж до"
            value={tempFloorTo}
            onChange={(e) => setTempFloorTo(e.target.value)}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
  <Box
    display="flex"
    flexDirection={{ xs: 'column', sm: 'row' }}
    alignItems="center"
    gap={1}
  >
    <TextField
      size="small"
      label="Год постройки от"
      value={tempYearBuiltFrom}
      onChange={(e) => setTempYearBuiltFrom(e.target.value)}
      type="number"
      InputProps={{ inputProps: { min: 1930, max: 2025 } }}
      sx={{
        width: { xs: '100%', sm: 180 },
      }}
    />
    <TextField
      size="small"
      label="Год постройки до"
      value={tempYearBuiltTo}
      onChange={(e) => setTempYearBuiltTo(e.target.value)}
      type="number"
      InputProps={{ inputProps: { min: 1930, max: 2025 } }}
      sx={{
        width: { xs: '100%', sm: 180 },
      }}
    />
  </Box>
</Box>


      {/* Фильтр по количеству комнат */}
      <RoomsAutocomplete
        roomsOptions={roomsOptions}
        selectedRooms={selectedRooms}
        handleRoomChange={handleRoomsChange}
      />

      {/* Отдельный контейнер для поиска */}
      <Grid item xs={12} sm={4}>
        <Box display="flex" flexDirection="column">
          <TextField
            fullWidth
            label="Поиск по ссылке или адресу"
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Вставьте ссылку avito или начните вводить улицу"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={() => setSearchTerm('')} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default CommonFilterForm;
