import React from 'react';
import DefaultCard from './DefaultCard';
import PurchasedCard from './PurchasedCard';

export default function SubjectCard({
  subject,
  onSubjectUpdate,
  setSubjects,
  fetchExtendedAboutData,
  setExtendedDataMap,
  onGlobalSubjectUpdate,
}) {
  if (!subject) return null;

  const isPurchased = subject.purchased === true;

  // 💥 используем ключ с purchased, чтобы React точно пересоздал компонент при изменении
  return (
    <div key={`${subject.id}-${isPurchased ? 'purchased' : 'default'}`}>
      {isPurchased ? (
        <PurchasedCard
          subject={subject}
          fetchExtendedAboutData={fetchExtendedAboutData}
          setExtendedDataMap={setExtendedDataMap}
          onGlobalSubjectUpdate={onGlobalSubjectUpdate}
        />
      ) : (
        <DefaultCard
          subject={subject}
          onSubjectUpdate={onSubjectUpdate}
          setSubjects={setSubjects}
          onGlobalSubjectUpdate={onGlobalSubjectUpdate}
        />
      )}
    </div>
  );
}
