// src/components/RegistrationPage.jsx
import React, { useState } from 'react';
import axios from 'axios';
import RegistrationFormStep1 from './RegistrationFormStep1';
import VerificationForm from './VerificationForm';

// Функция для получения CSRF-токена из cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function RegistrationPage() {
  const [step, setStep] = useState(1);

  // Поля формы (шаг 1)
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [role, setRole] = useState('private'); // по умолчанию "частное лицо"
  const [selectedCities, setSelectedCities] = useState([]); // список id городов

  const [personalDataAgreement, setPersonalDataAgreement] = useState(false);
  const [newsletterAgreement, setNewsletterAgreement] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);

  // Поле (шаг 2)
  const [verificationCode, setVerificationCode] = useState('');

  // Сообщения об ошибках/успехе
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  // Обработчик регистрации
  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setInfoMessage('');

    if (password !== password2) {
      setErrorMessage('Пароли не совпадают');
      return;
    }

    try {
      const csrfToken = getCookie('csrftoken');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/accounts/register/`,
        {
          username,
          email,
          phone,
          password,
          password2,
          personal_data_agreement: personalDataAgreement,
          newsletter_agreement: newsletterAgreement,
          terms_agreement: termsAgreement,
          // новые поля
          role,
          // для M2M — отправим список id выбранных городов
          // (На бэкенде нужно обработать, чтобы сохранить в Profile.allowed_cities)
          allowed_cities: selectedCities,
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': csrfToken
          }
        }
      );

      setInfoMessage('Регистрация успешна! Проверьте почту и введите код.');
      setStep(2);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('Ошибка при регистрации');
      }
    }
  };

  // Обработчик верификации
  const handleVerify = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setInfoMessage('');
    const csrfToken = getCookie('csrftoken');

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/accounts/verify/`,
        {
          email,
          code: verificationCode
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': csrfToken
          }
        }
      );

      setInfoMessage('Аккаунт подтвержден и Вы залогинены!');
      // Редирект, например, на /lite_subjects
      setTimeout(() => {
        window.location.href = '/lite_subjects';
      }, 1000);

    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('Ошибка при верификации');
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-4 bg-white shadow-md rounded">
      {step === 1 && (
        <RegistrationFormStep1
          username={username} setUsername={setUsername}
          email={email} setEmail={setEmail}
          phone={phone} setPhone={setPhone}
          password={password} setPassword={setPassword}
          password2={password2} setPassword2={setPassword2}
          personalDataAgreement={personalDataAgreement} setPersonalDataAgreement={setPersonalDataAgreement}
          newsletterAgreement={newsletterAgreement} setNewsletterAgreement={setNewsletterAgreement}
          termsAgreement={termsAgreement} setTermsAgreement={setTermsAgreement}
          errorMessage={errorMessage} setErrorMessage={setErrorMessage}
          infoMessage={infoMessage} setInfoMessage={setInfoMessage}

          role={role} setRole={setRole}
          selectedCities={selectedCities} setSelectedCities={setSelectedCities}

          onSubmit={handleRegister}
        />
      )}

      {step === 2 && (
        <VerificationForm
          email={email}
          verificationCode={verificationCode} setVerificationCode={setVerificationCode}
          errorMessage={errorMessage} setErrorMessage={setErrorMessage}
          infoMessage={infoMessage} setInfoMessage={setInfoMessage}
          onVerify={handleVerify}
        />
      )}
    </div>
  );
}