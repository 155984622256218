// SubjectCard.jsx
import React from 'react';
import DefaultCard from './DefaultCardList';
import PurchasedCard from './PurchasedCardList';

export default function SubjectCard({ subject, onSubjectUpdate, setSubjects, onGlobalSubjectUpdate }) {
    // console.log('[SubjectCard] subject:', subject);

  if (!subject) return null;

  // Если поле purchased === true => показываем купленную карточку
  if (subject.purchased === true) {
    return <PurchasedCard subject={subject} setSubjects={setSubjects} onGlobalSubjectUpdate={onGlobalSubjectUpdate} />;
  }

  // Иначе карточка «не куплена»
  return (
    <DefaultCard
      subject={subject}
      onSubjectUpdate={onSubjectUpdate}
      setSubjects={setSubjects}
      onGlobalSubjectUpdate={onGlobalSubjectUpdate}
    />
  );
}
