// DefaultCard.jsx
import React from 'react';
import PurchaseButton from './PurchaseButton';
import SubjectImage from '../IMG/SubjectImage';

function FeatureBlock({ label, value }) {
  let isTruthy = false;
  if (typeof value === 'boolean') {
    isTruthy = value;
  } else if (typeof value === 'number') {
    isTruthy = value > 0;
  } else if (typeof value === 'string') {
    isTruthy = value.trim() !== '';
  }
  const checkIcon = isTruthy ? '✔' : '✖';
  const checkColor = isTruthy ? 'text-emerald-500' : 'text-gray-400';
  let displayStr = label;
  if (typeof value === 'number') {
    displayStr += `: ${value}`;
  } else if (typeof value === 'string' && value.trim() !== '') {
    displayStr += `: ${value}`;
  }
  return (
    <div className="flex items-center bg-white border border-gray-200 rounded-md px-3 py-1 shadow-sm text-xs">
      <span className={`${checkColor} mr-1`}>{checkIcon}</span>
      <span>{displayStr}</span>
    </div>
  );
}

export default function DefaultCard({ subject, onSubjectUpdate, setSubjects, onGlobalSubjectUpdate }) {
  const {
    id,
    name,
    adress,
    activ,
    has_photos,
    history_counts,
    duplicates_count,
  } = subject;
  const statusEmoji = activ ? '✅ Активно' : '❌ Неактивно';
  const addrHtml = adress ? `📍 ${adress}` : '';
  const priceChangeCount = history_counts?.price_change_count ?? 0;
  const targetChangeCount = history_counts?.target_change_count ?? 0;

  return (
    <div className="p-4 w-full bg-white rounded-xl shadow-md font-sans">
      <div className="flex mb-4">
      <div className="w-28 h-28 mr-4">
        <SubjectImage subjectId={id} size={112} />
      </div>
        <div className="flex-1">
          <div className="font-semibold text-gray-900 text-base mb-1">
            {name || `Объект #${id}`}
          </div>
          {addrHtml && (
            <div className="text-gray-500 text-sm mb-1">{addrHtml}</div>
          )}
          <div className="text-gray-500 text-sm">{statusEmoji}</div>
          {/* Компонент для покупки */}
          <PurchaseButton subjectId={id} onPurchaseSuccess={onSubjectUpdate} setSubjects={setSubjects}  onGlobalSubjectUpdate={onGlobalSubjectUpdate} />
        </div>
      </div>
      <div>
        <div className="font-bold mb-2 text-sm text-gray-700">
          Доступная информация в базе:
        </div>

        <div className="flex flex-wrap gap-2">
          <FeatureBlock label="Фото" value={has_photos} />
          <FeatureBlock label="Изм.цен" value={priceChangeCount} />
          <FeatureBlock label="Историч.записей" value={targetChangeCount} />
          <FeatureBlock label="Дубликаты" value={duplicates_count} />
          <div className="flex items-center bg-blue-50 text-blue-600 border border-blue-200 rounded-md px-3 py-1 shadow-sm text-xs font-medium">
  <span className="mr-1">✔</span>
  <span>Есть ссылка</span>
</div>
        </div>
      </div>
    </div>
  );
}
